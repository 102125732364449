//?                                                     PROFILE

.username{
    font-size: 26px;
    width: 600px;
}
.infoItem{
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
}
  
.infoItemLabel{
    font-weight: 400;
    opacity: .75;
    font-size: 12;
}
  
.infoItemValue{
    font-weight: 600;
    text-align: right;
    font-size: 12;
}

.hover:hover{
    background-color: #4890ff3b;
    border-radius: 4px;
}


//?                                                     PROFILE