.card {
  width: 30%;
  height: 60vh;
  max-height: 60vh;
  overflow: hidden;
  margin: 12px;
  min-width: 360px;
}

.innerCard {
  padding: 6px;
}

.wraper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: "";
}

.cardContainer p {
  margin: 0;
}
.cardContainer > .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -16px;
}
.cardContainer > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 16px;
  background: #fff;
}
.cardContainer > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.cardContainer > .ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .cardContainer > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
.cardContainer > .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .cardContainer > .ant-tabs-card .ant-tabs-tab-active {
  background: #fff;
  border-color: #fff;
}
#components-tabs-demo-card-top .code-box-demo {
  padding: 24px;
  overflow: hidden;
  background: #f5f5f5;
}
[data-theme='compact'] .cardContainer > .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -8px;
}
[data-theme='dark'] .cardContainer > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}
[data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
  background: #000;
}
[data-theme='dark'] .cardContainer > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  background: #141414;
}
[data-theme='dark'] .cardContainer > .ant-tabs-card .ant-tabs-tab-active {
  background: #141414;
  border-color: #141414;
}