//* Variables

$base-width: "100%";

//?                                                     ABOUT

.flexRow{
    display: "flex";
    flex-direction: "row";
    justify-content: "space-between";
    width: 100%;
}

.orderName{
    font-size: 24px;
    font-weight: 600;
}

.orderStatus{
    font-size: 14;
    font-weight: 400;
    opacity: .75;
    margin-top: -12px;
    margin-bottom: 20px;
}

//?                                                     ABOUT

.infoItem{
    width: $base-width;
    max-width: $base-width;
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
}

.infoItemLabel{
    font-weight: 400;
    opacity: .75;
    font-size: 12;
}

.infoItemValue{
    font-weight: 600;
    text-align: right;
    font-size: 12;
}

.aboutFlex{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-right: 48px;
}

.aboutFlex h1{
    font-size: 24px;
    padding-bottom: 8px;
}

.aboutFlex p{
    margin-bottom: 2px;
}

//?                                                     ABOUT

//?                                                     TASKS

.taskContainer{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: $base-width;
}

.taskTitle{
    font-size: 24px;
    font-weight: 600;
}

.taskDescription{
    font-size: 14px;
    font-weight: 400;
    margin-left: 32px;
    line-height: 26px;
}

.taskMaterialsCountEmpty{
    font-size: 14px;
    font-weight: 400;
    margin-left: 32px;
    margin-top: 20px;
    opacity: .5;
}

.taskMaterialsCount{
    font-size: 14px;
    font-weight: 400;
    margin-left: 32px;
    margin-top: 20px;
    color: #4675ff;
    text-decoration: underline;
    cursor: pointer;
}

//?                                                     TASKS

//?                                                     REPORTS

.reportItemContainer{
    width: $base-width;
    max-width: $base-width;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 4px 0;
    margin-bottom: 32px;
}

.reportsFlexRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.reportUsername{
    font-size: 20px;
    font-weight: 600;
}

.reportDate{
    font-size: 14px;
    font-weight: 500;
}

.reportMessage{
    line-height: 28px;
    font-size:  18px;
}

.textArea{
    margin-bottom: 1em;
    margin-top: -1px;
    padding: 4px 8px;
    outline-color: #cacaca !important;
}

.reportText{
    padding: 5px 10px;
    cursor: default;
}

//?                                                     REPORTS

//?                                                     SECTION TITLE

.sectionTitle{
    font-size: 24px;
    font-weight: 600;
}

.sectionTitleSubtext{
    font-size: 14px;
    font-weight: 400;
    margin-top: -10px;
    padding-bottom: 24px;
    margin-bottom: 12px;
    width: $base-width;
    border-bottom: 1px solid #ddd;
}

//?                                                     SECTION TITLE
