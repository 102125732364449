.machineHeaderContainer {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 950px;
  margin-bottom: 20px;
}

.info {
  // margin-left: 20px;
  display: flex;
  flex-direction: row;
}

.machineImage {
  height: 100%;
}

.machineName {
  font-size: 26px;
  font-weight: 600;
  color: #000;
  margin-right: 20px;
}

.tagStyle {
  height: 22px;
  user-select: none;
}

.switchInfo {
  font-size: 14px;
  font-weight: 400;
  opacity: 0.65;
  color: #000;
  margin-right: 6px;
  width: max-content;
}

.infoFlex {
  padding: 0;
  display: flex;
  align-items: center;
  width: 700px;
  padding: 0;
}

.flex {
  display: flex;
  align-items: center;
  padding: 0;
}

@media (max-width: 576px) {
  .machineImage {
    height: unset;
    width: 50%;
  }

  .info {
    width: 50%;
  }

  .switchInfo {
    margin: 20px 0;
    margin-right: 12px;
  }

  .machineName {
    font-size: 12px;
    margin-right: 0;
    margin-bottom: 12px;
  }

  .infoFlex {
    align-items: flex-start;
    flex-direction: column;
  }
}

.openCloseButton {
  padding: 6px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  border: 1px solid;
  border-radius: 3px;
}

//?                                                     SECTION TITLE

.sectionTitle{
  font-size: 24px;
  font-weight: 600;
}

.sectionTitleSubtext{
  font-size: 14px;
  font-weight: 400;
  margin-top: -10px;
  padding-bottom: 24px;
  margin-bottom: 12px;
  border-bottom: 1px solid #ddd;
}

//?                                                     SECTION TITLE

//?                                                     ABOUT

.infoItem{
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
}

.infoItemLabel{
  font-weight: 400;
  opacity: .75;
  font-size: 12;
}

.infoItemValue{
  font-weight: 600;
  text-align: right;
  font-size: 12;
}

//?                                                     ABOUT

//?                                                     INSTRUCTIONS

.instructionsTitleContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed #dedede;
  padding-bottom: 32px;
}

.instructionsTitleMainText{
  font-size: 18px !important;
  font-weight: 600 !important;
}

.instructionsTitleSecondaryText{
  font-size: 12px !important;
  font-weight: 400 !important;
  opacity: .75 !important;
  margin-top: -4px !important;
}

.instructionsRelevantLinks{
  opacity: .4 !important;
  transition: 250ms ease;
  cursor: pointer;
}

.instructionsRelevantLinks:hover{
  opacity: 1 !important;
  transition: 250ms ease;
}

//?                                                     INSTRUCTIONS