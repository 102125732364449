.buttonsContainer{
    position: absolute;
    width: 752px;
    left: calc(50% - 376px);
    z-index: 99;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 18px;
}

.filterButton{
    height: 34px;
    margin: 0 6px
}

.addButton{
    height: 34px;
    margin-right: 80px;
}

.loadingContainer, .errorContainer{
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.errorText{
    font-size: 32px;
    margin-top: 20px;
    margin-bottom: 12px;
}

.eventDisplay{
    width: 100%;
    display: flex;
    padding: 6px;
    border-radius: 4px;
}

.eventTime{
    font-size: 12px;
    font-weight: 300;
    margin-right: 6px;
    color: #FFF;
    margin-bottom: 0;
    padding-top: 2px;
    padding-bottom: 0;
}

.eventTitle{
    font-size: 14px;
    font-weight: 600;
    color: #FFF;
    margin-bottom: 0;
    padding-bottom: 0;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.eventContainer{
    width: 240px;
}

.titleContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.title{
    font-size: 18px;
    font-weight: 600;
    margin-top: 6px;
    margin-left: 12px;
    color:#434343;
}

.titleIcon{
    color: #e5f7ff;
    font-size: 16px;
    margin-top: -9px;
    background-color: #2d3b51;
    padding: 6px;
    border-radius: 4px;
}

.eventContainerButtons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.eventButton{
    width: 44%;
    margin-bottom: 6px;
    height: 34px;
    font-size: 12px;
    text-align: center;
}

.divider{
    width: calc(100% + 34px);
    margin-left: -17px;
    margin-bottom: 16px;
    height: 1px;
    background-color: #efefef;
}

.groupLabel{
    font-size: 12px;
    font-weight: 300;
    opacity: .75;
    margin-bottom: 0px;
}

.groupValue{
    font-size: 16px;
    font-weight: 500;
    color: #434343;
    margin-top: 0px;
}

@media screen and (max-width: 1420px){
    .buttonsContainer{
        position: relative;
    }
}