.cardWrapper{
    overflow: hidden;
    padding: 7px;
    width: 280px;
    height: 240px;
    vertical-align: top;
    position: relative;
    cursor: pointer;
    border-radius: 20px;
    margin-bottom: 20px;
    margin-right: 12px;
}

.card{
    flex: 1 1 auto;
    overflow: hidden;
    border-radius: 6px;
}

.metaContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: -20px;
    min-width: max-content;
    flex-shrink: 0;
}

.metaName{  
    margin-left: 10px;
    flex: 1 2 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
}

.listFlex{
    min-width: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.listTitle{
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cardContainer{
    display: grid;
    place-items: center;
    object-fit: cover;
    overflow: hidden !important;
    position: relative;
}

.cardHoverStyle{
    transition: 250ms ease;
}

.cardWrapper:hover .cardHoverStyle{
    transform: scale(1.025);
    transition: 250ms ease;
}

.document{
    transition: 250ms ease;
    transform: translate(-4px,  -4px);
    position: relative;
    cursor: pointer;
}

.cardWrapper:hover .document{
    transform: scale(1.025) translate(-4px,  -4px);
    transition: 250ms ease;
}

.documentLine{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #fff;
}

.documentOverlay{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
}

/* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::    File Viewer */

.fileViewerContainer{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1024;
    background-color: rgba(0,0,0,0.7);
}

.fileViewerToolbar{
    width: 100%;
    height: 52px;
    background: linear-gradient(90deg, rgba(0,0,0,0.9023984593837535) 0%, rgba(0,0,0,0.7) 100%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.fileViewerToolbarLeftSide{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.fileViewerCarouselWrapper{
    width: 100%;
    height: 100%;
    padding-bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.fileViewerCarouselItemFallback{
    width: 100%;
    height: calc(100vh - 100px);
    line-height:  calc(100vh - 120px);
    color: #fff;
    text-align: center;
    font-size: 20px;
    opacity: .5;
}

.fileViewerCarousel{
    width: max-content;
    max-width: 1200px;
    height: 100%;
    position: relative;
}

.fileViewerCarouselPrevArrow{
    width: 48px !important;
    height: 48px !important;
    left: -80px !important;
    top: calc(50% - 24px) !important;
    background-color: rgba(0,0,0,0.9) !important;
    border-radius: 100px !important;
    display: grid;
    opacity: .85 !important;
    transition: 250ms ease;
}

.fileViewerCarouselPrevArrow:hover{
    opacity: 1 !important;
    transition: 250ms ease;
}

.fileViewerCarouselNextArrow{
    width: 48px !important;
    height: 48px !important;
    right: -80px !important;
    top: calc(50% - 24px) !important;
    background-color: rgba(0,0,0,0.9) !important;
    border-radius: 100px !important;
    display: grid;
    opacity: .65 !important;
    transition: 250ms ease;
}

.fileViewerCarouselNextArrow:hover{
    opacity: 1 !important;
    transition: 250ms ease;
}

.fileViewerCarouselItemWrapper{
    width: max-content;
    min-width: 1024px;
    height: calc(100vh - 100px);
    position: relative;
    overflow: hidden;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.fileViewerCarouselImageWrapper{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media only screen and (max-width: 1300px) {
    .fileViewerCarousel{
        width: max-content;
        max-width: 80vw;
    }
}