// ::::::::::::::::::::::::::::::::::::::::::::::::::::::: CARDS
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::: CARDS
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::: CARDS

.flexRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.value{
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-weight: 600;
}
.label{
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 300;
    color: red;
}

.togglePopover{
    position: absolute;
    height: max-content;
    top: -8px;
    right: -8px;
    display: flex;
    flex-direction: column;
    opacity: .5;
    cursor: pointer;
    transition: 250ms ease;
    padding: 20px;  
}

.togglePopover:hover{
    opacity: 1;
    transition: 250ms ease;
}

.togglePopoverCircle{
    width: 3px;
    height: 3px;
    border-radius: 5px;
    background-color: #434343;
    margin: 1px 0px;
}

// -------------------------------------------------------------

#informationCard{
    min-width: 180px;
    height: 100px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 8px 20px;
    border-radius: 4px;
    position: relative;
}

#informationCard .iconBackground{
    width: 60px;
    height: 60px;
    min-width: 60px;
    border-radius: 50%;
    background-color: #EDF2FF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}

#informationCard .icon{
    width: 30px;
    height: 30px;
}


// -------------------------------------------------------------


#availabilityCard{
    min-width: 240px;
    height: 100px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 8px 20px;
    border-radius: 4px;
    position: relative;
}

#availabilityCard .infoContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

// -------------------------------------------------------------

// ::::::::::::::::::::::::::::::::::::::::::::::::::::::: CARDS
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::: CARDS
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::: CARDS





// ::::::::::::::::::::::::::::::::::::::::::::::::::::::: MAIN PAGE
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::: MAIN PAGE
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::: MAIN PAGE


#maintenancesContainer{
    width: 100%;
    height: calc(100vh - 310px);
    overflow-y: scroll;
    overflow-x: hidden;
    // border: 1px solid #dedede;
    box-sizing: border-box;
    padding: 20px;
    position: relative;
}


// ::::::::::::::::::::::::::::::::::::::::::::::::::::::: MAIN PAGE
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::: MAIN PAGE
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::: MAIN PAGE





// ::::::::::::::::::::::::::::::::::::::::::::::::::::::: CHARTS
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::: CHARTS
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::: CHARTS


#donutChart{
    // height: 400px;
    // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 24px;
    display: flex;
    flex-direction: column;
    position: relative;
}

#donutChart .header{
    padding-bottom: 16px;
}

#donutChart .content{
    width: 100%;
    height: calc(100% - 40px);
    box-sizing: border-box;
    padding-top: 24px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

#donutChart .flexRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

#donutChart .chartLabel{
    // height: 20%;
    margin-right: 10px;
    margin-bottom: 10px;
}

#donutChart .ch_label{
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 300;
}

#donutChart .ch_value{
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
}

#donutChart .labels{
    width: 35%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

#donutChart .circle{
    width: 8px;
    height: 8px;
    border-radius: 8px;
    margin-right: 8px;
}

#donutChart .chart{
    width: 240px;
    height: 240px;
    margin-right: 0px;
}


// -------------------------------------------------------------


.lineChart{
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1000px){
    .lineChart{
        height: 400px;
    }
}

.barChart{
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}


// ::::::::::::::::::::::::::::::::::::::::::::::::::::::: CHARTS
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::: CHARTS
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::: CHARTS