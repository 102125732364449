body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.order-information {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.order-information .label {
  color: rgba(0, 0, 0, 0.85);
  font-size: 15px;
  font-weight: 300;
  padding: 0;
  margin: 0;
}

.order-information .value {
  font-weight: 600;
  font-size: 16px;
  padding: 0;
  margin: 0;
  text-align: right;
}

.columnsList {
  position: absolute;
  min-width: 210px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.15s ease-out;
  background-color: white;
  z-index: 12345679;
}

.IconDropDown {
  transition: transform 0.15s ease-out;
}


.ListItemCustomClass{
    cursor: pointer;
}


.categoryMaintenanceBorder{
  border: 0px solid black;
  text-align: center;
}
.categoryMaintenanceBorder span{
  font-weight: 600;
  font-size: 16px;
}

textarea {
  resize: none; /* Prevent users from resizing the textarea */
  overflow-y: hidden; /* Hide vertical scrollbar */
}

iframe{
  display: none !important; /* Hide the iframe */
}

#avg-user-response-time .ant-table-row td:nth-last-child(2){
  background-color: #f1f1f1;
}

#avg-user-response-time .ant-table-footer{
  padding: 0px;
}

.avg-printable-content .table-title{
  display: none;
}


@media print {
  .avg-printable-content {
    padding: 40px;
  }
  
  .avg-printable-content .table-title{
    display: block;
    font-weight: 600;
    margin-bottom: -20px;
  }

  .avg-printable-content .ant-table-thead > tr > th, .ant-table-tbody > tr > td{
    padding: 8px !important;
  }  
}
