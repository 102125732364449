.popoverContainer{
    width: 500px;
}

.titleContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.title{
    font-size: 18px;
    font-weight: 600;
    margin-top: 6px;
    margin-left: 12px;
    color:#434343;
}

.titleIcon{
    color: #e5f7ff;
    font-size: 16px;
    margin-top: -9px;
    background-color: #2d3b51;
    padding: 6px;
    border-radius: 4px;
}

.divider{
    width: calc(100% + 34px);
    margin-left: -17px;
    margin-bottom: 16px;
    height: 1px;
    background-color: #efefef;
}

.text{
    line-height: 24px;
}