html {
  scroll-behavior: smooth !important;
}

body {
  // background: #e6e9ed;
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px #4675ff;
  background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    height: 2px;
    width: 2px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05);
  }
}

 
#dashboard-table
  > div
  > div
  > div.ant-table-header.ant-table-hide-scrollbar
  > table
  > thead
  > tr
  > th {
  user-select: none !important;
}

.ant-progress-text {
  font-weight: 600;
  // color: #57a0ee !important;
}

.ant-typography-edit {
  float: right;
  font-size: 18px;
  margin-right: 12px;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.ant-page-header {
  padding: 0 !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cardAnalytics {
  width: 30%;
  height: 72vh;
  max-height: 72vh;
  overflow: hidden;
  margin: 12px;
  min-width: 360px;
}

.listTreeItem:hover {
  background-color: #4890ff3b;
  border-radius: 4px;
}

.parentListTreeItem{
  background-color: #4890ff1a;
}

#tableSection {
  max-height: 87vh;
}

.ant-list-header {
  padding-top: 0 !important;
}

.reportsFilters .ant-drawer-content-wrapper {
  height: 139px !important;
}

.reportsFilters.height {
  height: 139px !important;
}

.reportsFilters .ant-drawer-mask {
  display: none !important;
}

.reportsFilters .ant-drawer-close {
  line-height: 40px !important;
  height: 39px !important;
}

.category-list {
  padding-top: 0;
  font-size: 20px;
}

.category-list-item {
  padding: 4px 0;
}

.hover:hover {
  cursor: pointer;
}

.ant-menu-item a,
.ant-menu-item i {
  font-size: 14px !important;
}

@media (max-width: 576px) {
  .ant-list {
    margin-left: 0 !important;
  }

  .modal-header .ant-form-item-label label {
    font-size: 20px;
    opacity: 0.85;
  }

  .category-list-item {
    padding: 8px 0;
  }
}

.confirm-delete {
  background-color: #fff;
  transform: scaleX(1) !important;
  position: absolute;
  height: auto !important;
  width: 100% !important;
  transform-origin: right;
  transition: 0ms ease;
}

.confirm-delete button {
  height: 26px;
  margin: 0 10px;
}

.logo {
  width: 120px;
  height: 80px;
  margin: 0 auto;
}

#login-container {
  width: 100%;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  padding: 20vh 0;
}

#login-form {
  background: #fff;
  width: 100%;
  max-width: 240px;
  margin: 0 auto;
  border-radius: 6px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
}

.chat {
  height: 60vh;
  max-height: 60vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
}

.chat .chat__message .ant-comment-inner {
  padding: 6px 0;
}

.themesTableRow:hover {
  cursor: pointer;
  background: lightcyan;
}

.showCheckbox {
  display: none !important;
}

.ant-select-selection__rendered .showCheckbox {
  display: inline-block !important;
  margin: 0 5px;
}

.hideCheckBox .showCheckbox {
  display: none !important;
}

.ShowFavorites .ant-list-item {
  display: none;
}

.ShowFavorites .ant-list-item.favorit {
  display: flex;
}

.disabledAddIcon {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  cursor: initial !important;
}

.notReadNotification .ant-collapse-header {
  border-left: 3px solid #faad14;
}

.redNotification {
  margin-left: 3px;
}

.ant-dropdown-link:hover {
  cursor: pointer;
}


.accoutingNavigator {
  // margin-bottom: 30px;
}

.accoutingNavigator a.active {
  color: #1890ff;
}

.accoutingNavigator a:hover {
  color: #1890ff;
}

.accoutingNavigator a {
  color: rgba(0, 0, 0, 0.45);
  font-size: 18px;
  margin-right: 18px;
}

.content {
  background: #fff;
  padding: 24px;
  margin: 24px;
  min-height: 280;
}

.content-xs,
.content-sm {
  padding: 6px;
  margin: 6px;
}

.content-xs {
  padding:3;
}

@media (min-width: 1024px) {
  .menu {
    display: none !important;
  }
}

.ant-drawer-body {
  padding: 9px !important;
}

hr {
  border: 1px solid #d9d9d9;
}

.antdModal {
  width: calc(100% - 12px) !important;
}

.dispalyNoneLabel label {
  display: none;
}

.machineForm .ant-form-item,
.userForm .ant-form-item {
  margin-bottom: 6px;
}

.ant-form-item{
  margin-bottom: 6px !important;
}

.ant-list-item.documents{
  padding: 0;
}

@media (max-width: 576px) {
  .ant-page-header-heading-extra {
    display: inline !important;
    float: right !important;
    width: unset !important;
    padding-top: 0px !important;
    overflow: hidden !important;
  }
}
.ant-calendar-range-picker-input {
  // font-size: 18px;
  // color: black;
  // padding: 3px;
  // height: 20px;
}

// #tabs-wrapper {
//   padding: 0;
// }

// // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::  tabs

// #order-tabs .ant-tabs-tab {
//   width: 30vw;
//   margin-right: 0;
//   padding: 12px 0;
//   text-align: center;
// }

// #order-tabs .ant-tabs-nav-container-scrolling {
//   padding-right: 0;
//   padding-left: 0;
// }

// #order-tabs .ant-tabs-tab-prev,
// #order-tabs .ant-tabs-tab-next {
//   display: none;
// }

// #machine-tabs .ant-tabs-tab {
//   border: 1.5px solid #ddd;
//   border-bottom: 3.25px solid #ddd;
//   border-radius: 6px;
//   width: 140px;
//   justify-content: center;
//   align-items: center;
//   text-align: center;
//   margin-right: 18px !important;
//   transition: 250ms ease !important;
//   user-select: none;
// }

// #machine-tabs .ant-tabs-tab:hover {
//   border: 1.5px solid #bbb;
//   border-bottom: 3.25px solid #bbb;
//   transition: 250ms ease !important;
// }

// #machine-tabs .ant-tabs-tab a {
//   color: #888;
//   transition: 250ms ease;
// }

// #machine-tabs .ant-tabs-tab-active {
//   border: 1px solid #1890ff;
// }

// #machine-tabs .ant-tabs-tab-active:hover {
//   border: 1px solid #1890ff;
// }

// #machine-tabs .ant-tabs-tab-active a {
//   color: #1890ff;
// }

// #machine-tabs .ant-tabs-bar {
//   border-bottom: none !important;
// }

// #machine-tabs .ant-tabs-ink-bar {
//   width: 130px !important;
//   margin-left: 5px;
//   margin-bottom: 1px;
// }

// @media (max-width: 576px) {
//   #machine-tabs .ant-tabs-nav-container-scrolling {
//     padding: 0 !important;
//   }

//   #machine-tabs .ant-tabs-ink-bar {
//     display: none;
//   }

//   #machine-tabs .ant-tabs-tab-prev,
//   #machine-tabs .ant-tabs-tab-next {
//     display: none;
//   }

//   #machine-tabs .ant-tabs-nav {
//     width: calc(100vw - 38px);
//     overflow-x: scroll;
//     scroll-snap-type: x mandatory;
//     scrollbar-width: none;
//   }

//   #machine-tabs .ant-tabs-nav::-webkit-scrollbar {
//     display: none;
//   }

//   #machine-tabs .ant-tabs {
//     width: calc(100vw - 38px);
//   }

//   #machine-tabs .ant-tabs-tab {
//     padding: 12px 0 !important;
//     width: 33vw;
//     margin-right: 18px !important;
//     scroll-snap-align: center;
//   }

//   #machine-tabs .ant-tabs-ink-bar {
//     width: calc(33vw - 4px) !important;
//     margin-bottom: 2px;
//     margin-left: 4px;
//   }
// }

@media screen and (max-width: 576px) {
  .ant-list-vertical .ant-list-item-main {
    min-width: 100% !important;
  }

  .ant-list-vertical .ant-list-item {
    flex-wrap: wrap !important;
  }

  .ant-list-item-meta-description div div {
    max-width: 100% !important;
  }
}

.blink_me {
  color: red;
  animation: blinker 1s linear infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(82, 196, 26, 0.7);
  }

  70% {
    box-shadow: 0 0 0 8px rgba(82, 196, 26, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(82, 196, 26, 0);
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.ant-page-header .ant-page-header-ghost {
  padding: 0 !important;
}

.newOrder .ant-modal {
  width: 98% !important;
}

#root > section > section > aside > div > ul > li.ant-menu-item.ant-menu-item-disabled{
  cursor: pointer !important;
  padding-left: 0px !important;
}


// ::::::::::::::::::::::::::::::::::::::::::::::::::::::::: Machine tabs

// ::::::::::::::::::::::::::::::::::::::::::::::::::::::::: Maintenance

#root
  > section
  > section
  > main
  > div.ant-page-header.ant-page-header-ghost
  > div
  > span {
  // src/containers/maintenance/index.js => PageHeader extra style
  width: 100% !important;
  display: flex;
  flex-direction: row;
}

#root
  > section
  > section
  > main
  > div.ant-page-header.ant-page-header-ghost
  > div
  > span
  > *:first-child {
  display: flex;
  justify-content: space-between;
}

// Tags

.default {
  width: 180px;
  height: 32px;
  padding-left: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  border-radius: 2px;
  background-color: #efefef !important;
  border: 1px solid #cecece !important;
  color: #434343;
}

.warning {
  width: 180px;
  height: 32px;
  padding-left: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  border-radius: 2px;
  background-color: #fef9e5 !important;
  border: 1px solid #fede94 !important;
  color: #fe8b00;
}

.processing {
  width: 180px;
  height: 32px;
  padding-left: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  border-radius: 2px;
  background-color: #e5f7ff !important;
  border: 1px solid #91d1ff !important;
  color: #4575ff;
}

.error {
  width: 180px;
  height: 32px;
  padding-left: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  border-radius: 2px;
  background-color: #fff0f0 !important;
  border: 1px solid #ff9b9b !important;
  color: #fe0017;
}

.success {
  width: 180px;
  height: 32px;
  padding-left: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  border-radius: 2px;
  background-color: #f3ffed !important;
  border: 1px solid #a6f98d !important;
  color: #38dd60;
}

// ::::::::::::::::::::::::::::::::::::::::::::::::::::::::: Maintenance

// ::::::::::::::::::::::::::::::::::::::::::::::::::::::::: Table Header

table th {
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}

table th:first-child {
  border-left: none;
}

table th:last-child {
  border-right: none;
}

// ::::::::::::::::::::::::::::::::::::::::::::::::::::::::: Table Header


.dashboardErrorsTable thead tr th{
  background-color: #ffb4a1 !important;
}

.dashboardPreventiveOrdersTable thead tr th{
  background-color: #c3ffa1 !important;
}
.dashboardControlOrdersTable thead tr th{
  background-color: #d4f1ff !important;
}