.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding:9px;
}

.container:hover{
    background-color: #4890ff3b;
    border-radius: 4px;
}


.textArea::placeholder {
  color: #bcbcbc !important;
}

.info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  width: 20px;
  opacity: 0.65;
  margin-top: 5px;
  margin-right: 8px;
}

.locationName {
  font-size: 16px;
  // padding: 4px 8px;
  margin: 0;
}

.textArea {
  margin-top: -4px;
  margin-bottom: 10px;
  font-size: 16px;
  padding: 2px 6px;
  width: 480px;
}
