.ant-table-column-title{
    user-select: none !important;
}

.container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    box-sizing: border-box;
}

.sectionLeft{
    height: 100%;
    width: 65%;
    box-sizing: border-box;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
}

.sectionRight{
    height: 100%;
    width: 35%;
    box-sizing: border-box;
    padding: 0 16px;
    display: flex;
    display: flex;
    flex-flow: column;
    position: relative;
    justify-content: space-between;
}

.sectionTitle{
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 0px;
    margin-bottom: 32px;
    margin-top: 8px;
    user-select: none;
}

.sectionTitleSpan{
    color: #685e5f;
}

.cardContainerOrders{
    padding: 32px 48px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
    width: 65%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-right: 32px;
}

.cardContainer:last-child{
    margin-bottom: 0px;
}

.cardName{
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 0px;
    user-select: none;
}

.cardDescription{
    font-weight: 300;
    margin-bottom: 32px;
    user-select: none;
}

.cardNumber{
    font-weight: bold;
    color: #685e5f;
    font-size: 16px;
}

.maintenancesContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.maintenancesChartContainer{
    height: 220px;
    width: 100%;
    cursor: pointer;
}

.maintenancesLabels{
    width: calc(100%  - 360px)
}

.doughnutLabels{
    display: flex;
    flex-direction: row;
    width: 320px;
}

.maintenancesLabel{
    font-weight: 300;
}

.maintenancesValue{
    font-weight: bold;
    margin-left: 8px;
}

.table{
    background-color: #fff;
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: auto;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
}

.machinesContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // justify-content: space-between;
}

.machinesContainerVertical{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.machinesChartContainerVertical{
    height: 200px;
    width: 40%;
    margin-bottom: 0px;
}


.machinesChartContainer{
    height: 240px;
    width: 100%;
}

.chartsFlexRow{
    width: 100%;
    height: 50%;
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
}

.chartsFlexRow:first-child{
    margin-bottom: 16px;
}

.machinesChartContainerHorizontal{
    height: 100px;
    width: 100%;
    cursor: pointer;
}

.cardContainerFlex{
    padding: 32px 48px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
    // margin-bottom: 32px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}


// Notes
.notes{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 35%;
    height: 100%;
    position: relative;
}

.note{
    width: 100%;
    height: calc(25% - 16px);
    background-color: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
    margin-bottom: 16px;
}

.note:last-child{
    margin-bottom: 0px;
}

.informationNote{
    width: 100%;
    max-width: 410px;
    min-width: 180px;
    background-color: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
    margin-bottom: 16px;
    cursor: pointer;
}

.notificationNote{
    width: 100%;
    height: 50%;
    max-height: 192px;
    // background-color: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1 1 auto;
    overflow: auto;
    position: relative;
    // Scroll snap
    scroll-snap-type: y mandatory;
}

.informationContainer{
    width: 100%;
    max-width: 400px;
    min-width: 180px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 8px 24px;
    height: 120px;
}



.informationIconBackground{
    position: relative;
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    border-radius: 50%;
    background-color: #FFF3DC;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
    box-sizing: border-box;
    user-select: none;
}


.informationIcon{
    width: 20px;
    height: 20px;
}

.informationFlex{
    margin-top: 4px;
    width: 100%;
}

.informationValue{
    font-size: 24px;
    font-weight: bold;
    margin-bottom: -2px;
    margin-top: 0px;
    user-select: none;
}

.informationDescription{
    font-size: 14px;
    font-weight: 300;
    opacity: .65;
    user-select: none;
    width: 100%;
}



.notificationContainer{
    height: 180px;
    margin: 6px 0;
    width: 100%;
    flex-shrink: 0;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    // flex: 1 1 auto;
    padding: 8px 24px;
    // Scroll snap
    scroll-snap-align: start;
}

.notificationHeader{
    height: 40px;
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.notificationIconBackground{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -8px;
    user-select: none;
}

.notificationIcon{
    width: 20px;
    height: 20px;
}

.notificationHeaderText{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.notificationHeaderTextMain{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: -2px;
    margin-top: 6px;
    user-select: none;
}

.notificationHeaderTextSecondary{
    font-size: 14px;
    font-weight: 300;
    opacity: .65;
    user-select: none;
}

.notificationBody{
   margin-top: 16px;
}

.notificationText{
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 26px;
    font-weight: 300;
}

.notificationText strong:first-child{
    font-weight: 700;
    margin-left: 0px;
}

.notificationText strong{
    font-weight: 700;
    margin-left: 4px;
}

.notificationArrowsContainer{
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


// Media
@media screen and (max-width: 1400px) {
    .sectionLeft{
        width: 60%;
        justify-content: flex-start;
    }
    
    .sectionRight{
        width: 40%;
    }

    .chartsFlexRow{
        flex-direction: column;
    }

    .cardContainerOrders{
        width: 100%;
    }

    .cardContainerFlex{
        width: 100%;
        margin-bottom: 16px;
    }

    .cardContainerFlex:first-child{
        margin-top: 16px;
    }

    .maintenancesChartContainer{
        height: 220px;
        // width: 180px
    }

    .doughnutLabels{
        width: 200px;
        font-size: 12px;
    }

    .chartsFlexRow:first-child{
        height: max-content;
        margin-bottom: 0px;
    }

    .chartsFlexRow:last-child{
        margin-top: 0px;
    }

    .notes{
        display: flex;
        flex-direction: row;
        align-items:flex-start;
        justify-content: space-between;
        width: 100%;
        position: relative;
        margin-top: 16px;
    }
    
    .note{
        margin-bottom: 16px;
        margin-top: 16px;
        margin-right: 16px;
        height: 180px;
        width: 25%;
    }
    
    .informationNotesFlex{
        position: relative;
        height: 100%;
    }

    .notificationNote{
        width: 50%;
        height: calc(100% - 16px);
        flex: 0 0 auto;
    }

    .notificationHeaderTextMain{
        font-size: 14px;
    }

    .notificationHeaderTextSecondary{
        font-size: 12px;
    }

    .informationValue{
        font-size: 18px;
    }
    
    .informationDescription{
        font-size: 12px;
    }

    .informationContainer{
        padding: 8px 16px;
        width: 100%;
    }

    .informationIconBackground{
        width: 32px;
        height: 32px;
        margin-right: 16px;
    }
    
    .informationIcon{
        width: 16px;
        height: 16px;
    }

    .notificationContainer{
        padding: 8px 16px;
    }

    .notificationText{
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        line-height: 22px;
    }

}

@media screen and (max-width: 1024px) {
    .container{
        flex-direction: column-reverse;
    }
    .sectionLeft{
        width: 100%;
    }
    
    .sectionRight{
        width: 100%;
        height: 400px;
    }

    .chartsFlexRow:last-child{
        flex-direction: row;
    }

    .cardContainerFlex{
        padding: 32px 48px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
        // margin-bottom: 32px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    .cardContainerFlex:last-child{
        margin-top: 16px;
    }

    .cardName{
        font-size: 20px;
    }

    // .notes{
    //     flex-direction: column;
    //     width: 100%;
    //     height: max-content;
    // }

    .notificationText{
        -webkit-line-clamp: 4;
    }

    // .informationNotesFlex{
    //     position: relative;
    //     height: 100%;
    //     width: 100%
    // }

    .notificationNote{
        // height: calc(100% - 16px);
    }

    .notificationHeaderTextMain{
        font-size: 18px;
    }

    .notificationHeaderTextSecondary{
        font-size: 14px;
    }

    .informationValue{
        font-size: 20px;
    }
    
    .informationDescription{
        font-size: 14px;
    }

    .notificationText{
        font-size: 14px;
    }

}

@media screen and (max-width: 768px) {
    .sectionLeft{
        padding: 0 4px;
    }
    
    .sectionRight{
        padding: 0 4px;
    }

    .notes{
        flex-direction: column;
        width: 100%;
        height: max-content;
    }

    .notificationText{
        -webkit-line-clamp: 4;
    }

    .informationNotesFlex{
        position: relative;
        height: 100%;
        width: 100%
    }

    .notificationNote{
        width: 100%;
        height: calc(100% - 16px);
    }

    .cardContainerOrders{
        padding: 24px;
    }

    .cardContainerFlex{
        padding: 24px;
    }

    .chartsFlexRow:last-child{
        flex-direction: column;
    }

    .cardContainerFlex{
        width: 100%;
    }

    .cardContainerFlex:last-child{
        margin-top: 0px;
    }
}