@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');

.appName{
    text-align: center;
    padding: 24px 0;
    font-size: 48px;
    padding-bottom: 0;
    margin-bottom: 4px;
    margin-top: -8px;
    font-family: 'Righteous', cursive;
}

.info{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.appLogo{
    width: 40px;
    height: 40px;
    margin-bottom: -12px;
    margin-top: 24px;
}
  
.loginText{
    width: 100%;
    text-align: center;
    padding: 10px 0;
    margin-top: -20px;
    margin-bottom: 32px;
    font-size: 14px;
    font-weight: 300;
    opacity: .8;
}

.logo {
    width: 120px;
    height: 80px;
    margin: 0 auto;
  }
  
.loginContainer {
    width: 100%;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    padding: 20vh 0;
    margin-top: -100px;
}

#login-container .ant-input-affix-wrapper{
  height: 38px !important;
}


.loginForm {
    background: #fff;
    width: 100%;
    max-width: 400px;
    height: max-content;
    padding-bottom: 32px !important;
    margin: 0 auto;
    border-radius: 6px;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    }

.ant-input-affix-wrapper{
    height: 38px;
}

.loginForm .ant-btn{
    width: 100px;
    height: 40px;
}

.loginForm .ant-form-item-required{
    font-size: 12px;
}