//?                                                     PROFILE

.username{
    font-size: 26px;
    width: 600px;
}

.userID{
    font-size: 14px !important;
    width: 600px;
    margin-top: -14px !important;
    font-weight: 400;
    opacity: .75;
}

.infoItem{
    width: 600px;
    max-width: 600px;
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
}
  
.infoItemLabel{
    font-weight: 400;
    opacity: .75;
    font-size: 12;
}
  
.infoItemValue{
    font-weight: 600;
    text-align: right;
    font-size: 12;
}

.backFlexRow{
    display: flex;
    flex-direction: row;
    width: 540px;
}

.hoverDiv{
    margin-top: 16px;
    margin-right: 20px;
}

.backIcon{
    font-size: 20px;
    margin-top: 6px;
}

.spacing{
    height: 1px;
    width: 600px;
    background-color: #dedede;
    margin: 16px 0;
    margin-bottom: 32px;
}

//?                                                     PROFILE


//?                                                     DOCUMENTS

.instructionsTitleContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed #dedede;
    padding-bottom: 32px;
    padding-top: 12px;
  }
  
.instructionsTitleMainText{
    font-size: 18px !important;
    font-weight: 600 !important;
}

.instructionsTitleSecondaryText{
    font-size: 12px !important;
    font-weight: 400 !important;
    opacity: .75 !important;
    margin-top: -4px !important;
}

.instructionsRelevantLinks{
    opacity: .4 !important;
    transition: 250ms ease;
    cursor: pointer;
}

.instructionsRelevantLinks:hover{
    opacity: 1 !important;
    transition: 250ms ease;
}

.formFlexRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
  
  //?                                                     DOCUMENTS